import ReconnectingWebSocket from './reconnect_websocket';

document.addEventListener('DOMContentLoaded', function () {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  const domain =
    protocol == 'ws:' ? '127.0.0.1:8000' : window.location.hostname;
  const chatMessages = document.getElementById('chat-messages');

  const contractId = document.querySelector('[name=contractid]')?.value;
  const liveChatWebsocketURL = `${protocol}//${domain}/ws/livechat/`;

  const liveChatSocket = new ReconnectingWebSocket(liveChatWebsocketURL);

  liveChatSocket.addEventListener('open', (event) => {});

  liveChatSocket.addEventListener('message', (event) => {
    let data = JSON.parse(event.data);
    data = JSON.parse(data.message);
    if (chatMessages && contractId == data.contract_id) {
      createChatMessage(data);
    } else {
      // notify the user of new message from ****
      let sender_name = data.sender_name;
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        iconColor: 'white',
        customClass: {
          popup: 'colored-toast',
        },
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: 'info',
        title: `New message from ${sender_name}`,
      });
    }
  });

  liveChatSocket.addEventListener('error', (event) => {
    console.error('WebSocket error:', event);
  });

  function createChatMessage(data) {
    const messageDiv = document.createElement('div');
    messageDiv.className = data.isUser
      ? 'relative flex flex-row-reverse w-full gap-3'
      : 'relative flex flex-row w-full gap-3';

    const messageAvatar = document.createElement('div');
    messageAvatar.className = 'flex items-end shrink-0';

    const messageAvatarImage = document.createElement('img');
    messageAvatarImage.src = data.profile_pic;
    messageAvatarImage.className =
      'rounded-full w-10 h-10 border-2 border-gray-100';

    messageAvatar.appendChild(messageAvatarImage);

    const messageContent = document.createElement('div');
    messageContent.className = 'flex flex-col max-w-md';

    const messageBubble = document.createElement('div');
    messageBubble.className = data.isUser
      ? 'bg-blue-600 text-white rounded-2xl rounded-br-none px-5 py-3 shadow-sm'
      : 'bg-gray-100 rounded-2xl rounded-bl-none px-5 py-3 shadow-sm';

    const messagePara = document.createElement('p');
    messagePara.className = data.isUser ? 'text-sm' : `text-sm text-gray-800`;
    messagePara.textContent = data.message;
    messageBubble.appendChild(messagePara);

    const messageTimestamp = document.createElement('span');
    messageTimestamp.className = 'text-xs text-gray-500 mt-1.5';
    messageTimestamp.textContent = 'Just now';

    messageContent.appendChild(messageBubble);
    messageContent.appendChild(messageTimestamp);
    messageDiv.appendChild(messageContent);

    messageDiv.appendChild(messageAvatar);
    messageDiv.appendChild(messageContent);

    chatMessages.appendChild(messageDiv);
  }
});
