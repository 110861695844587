document.addEventListener('DOMContentLoaded', function () {
  const chatForm = document.getElementById('chat-form');
  const chatInput = document.getElementById('chat-input');
  const contractId = document.querySelector('[name=contractid]')?.value;

  // Regex patterns to match contact info
  const contactInfoPattern =
    /(\b\d{3}[-.\s]??\d{3}[-.\s]??\d{4}\b)|(\b\d{1,4}[-.\s]??\d{1,4}[-.\s]??\d{1,4}[-.\s]??\d{1,9}\b)|(\+?[0-9]{1,4}[-.\s]?\(?[0-9]{1,4}?\)?[-.\s]?[0-9]{1,4}[-.\s]?[0-9]{1,9})|(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b)|(\bhttps?:\/\/[^\s]+)|(\bwww\.[^\s]+\.[^\s]+\b)/gi;

  chatForm?.addEventListener('submit', async function (e) {
    e.preventDefault();

    const message = chatInput.value.trim();
    if (!message) return;

    // Check if the message contains contact information
    if (contactInfoPattern.test(message)) {
      swal.fire({
        title: 'Contact Information Detected',
        text: 'Sharing contact information is not allowed.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }

    try {
      const response = await fetch(
        `/api/contract/livechat/send-message/?contract_id=${contractId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': document.querySelector('[name=csrfmiddlewaretoken]')
              .value,
          },
          body: JSON.stringify({
            message: message,
          }),
        },
      );

      if (!response.ok) {
        throw new Error(
          response.error || 'An error occurred while sending the message.',
        );
      } else {
        chatInput.value = '';
      }
    } catch (error) {
      swal.fire({
        title: 'Error',
        text: error,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  });
});
