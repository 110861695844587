document.addEventListener('DOMContentLoaded', function () {
  const searchInput = document.getElementById('contract_search_input');
  const clientTypeFilter = document.getElementById('contract_client_type');
  const refreshButton = document.getElementById('contracts_refresh_button');
  const contractsContainer = document.getElementById('contract_list_container');

  function applyFilters() {
    const searchQuery = searchInput?.value || '';
    const clientFilter = clientTypeFilter?.value || '';

    fetch(
      `/admin/contracts/api/get-contracts/?search=${searchQuery}&clientType=${clientFilter}`,
    )
      .then((response) => response.text())
      .then((html) => {
        contractsContainer.innerHTML = html;
      })
      .catch((error) => {});
  }

  searchInput?.addEventListener('input', applyFilters);
  clientTypeFilter?.addEventListener('change', applyFilters);
  refreshButton?.addEventListener('click', function () {
    searchInput.value = '';
    clientTypeFilter.value = '';
    applyFilters();
  });
});
