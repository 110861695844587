import ReconnectingWebSocket from './websocket/reconnect_websocket';

document.addEventListener('DOMContentLoaded', function () {
  let modal;
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  const domain =
    protocol == 'ws:' ? '127.0.0.1:8000' : window.location.hostname;

  const transactionWebsocketURL = `${protocol}//${domain}/ws/transaction/`;

  // Create a new WebSocket connection
  const transactionSocket = new ReconnectingWebSocket(transactionWebsocketURL);

  // Handle WebSocket connection events
  transactionSocket.addEventListener('open', (event) => {
    console.log('WebSocket connection opened:', event);
  });

  // Handle WebSocket message events
  transactionSocket.addEventListener('message', (event) => {
    let message = event;
    console.log('WebSocket connection opened:', message);
    let data = JSON.parse(message.data);
    verifyTransactionOnBackend(data);
  });

  transactionSocket.addEventListener('error', (event) => {
    console.log('WebSocket connection opened:', event);
  });

  // Handle checkout button click
  document.getElementById('checkout-button')?.addEventListener('click', (e) => {
    e.preventDefault();

    const button = document.getElementById('checkout-button');
    const loadingBtn = document.getElementById('loading-button');

    // Show diable loading button
    loadingBtn.classList.remove('hidden');
    button.classList.add('hidden');

    // Collect form data
    const formData = {
      first_name: document.querySelector('input[name="first_name"]').value,
      last_name: document.querySelector('input[name="last_name"]').value,
      company_name: document.querySelector('input[name="company_name"]').value,
      email: document.querySelector('input[name="email"]').value,
      mobile: document.querySelector('input[name="mobile"]').value,
      country: document.querySelector('select[name="country"]').value,
      street_address: document.querySelector('input[name="street_address"]')
        .value,
      town_city: document.querySelector('input[name="town_city"]').value,
      postcode_zipcode: document.querySelector('input[name="postcode_zipcode"]')
        .value,
      package_id: document.getElementById('package-id').value,
    };

    fetch('/api/create-payment-link/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]')
          .value,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (
            data.payment_data.tx_ref &&
            data.payment_data.tx_ref.startsWith('0000000000')
          ) {
            let payment = {
              transaction_id: 0,
              status: 'successful',
              ignore_modal: true,
            };
            payment.transaction_id = data.payment_data.tx_ref;
            verifyTransactionOnBackend(payment);
          } else {
            // Payment details for Flutterwave
            const paymentData = {
              public_key: data.payment_data.public_key,
              tx_ref: data.payment_data.tx_ref,
              amount: data.payment_data.amount,
              currency: data.payment_data.currency,
              redirect_url: data.payment_data.redirect_url,
              payment_options: data.payment_data.payment_options,
              customer: {
                email: data.payment_data.customer.email,
                phone_number: data.payment_data.customer.phone_number,
                name: data.payment_data.customer.name,
              },
              customizations: {
                title: data.payment_data.customizations.title,
                description: data.payment_data.customizations.description,
                logo: data.payment_data.customizations.logo,
              },
              callback: function (payment) {
                payment.ignore_modal = false;
                verifyTransactionOnBackend(payment);
              },
              onclose: function (incomplete) {
                if (incomplete) {
                  Swal.fire({
                    title: 'Payment Cancelled',
                    text: 'Your payment has been cancelled.',
                    icon: 'warning',
                  });
                }
              },
            };

            // Open Flutterwave popup
            modal = FlutterwaveCheckout(paymentData);
          }
        } else {
          Swal.fire({
            title: 'Error',
            text: data.message,
            icon: 'warning',
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'An error occurred while processing your request.',
          icon: 'error',
        });
      })
      .finally(() => {
        // Re-enable button and hide loading button
        loadingBtn.classList.add('hidden');
        button.classList.remove('hidden');
      });
  });

  // Handle Upgrade Button Click
  document.getElementById('upgrade-button')?.addEventListener('click', (e) => {
    e.preventDefault();
    const button = document.getElementById('upgrade-button');
    const loadingBtn = document.getElementById('loading-button');

    // Show diable loading button
    loadingBtn.classList.remove('hidden');
    button.classList.add('hidden');

    // Collect form data
    const formData = {
      first_name: document.querySelector('input[name="first_name"]').value,
      last_name: document.querySelector('input[name="last_name"]').value,
      company_name: document.querySelector('input[name="company_name"]').value,
      email: document.querySelector('input[name="email"]').value,
      mobile: document.querySelector('input[name="mobile"]').value,
      country: document.querySelector('select[name="country"]').value,
      street_address: document.querySelector('input[name="street_address"]')
        .value,
      town_city: document.querySelector('input[name="town_city"]').value,
      postcode_zipcode: document.querySelector('input[name="postcode_zipcode"]')
        .value,
      package_id: document.getElementById('package-id').value,
    };

    fetch('/api/upgrade-payment-link/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]')
          .value,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (
            data.payment_data.tx_ref &&
            data.payment_data.tx_ref.startsWith('0000000000')
          ) {
            let payment = {
              transaction_id: 0,
              status: 'successful',
              ignore_modal: true,
            };
            payment.transaction_id = data.payment_data.tx_ref;
            verifyTransactionOnBackend(payment);
          } else {
            // Payment details for Flutterwave
            const paymentData = {
              public_key: data.payment_data.public_key,
              tx_ref: data.payment_data.tx_ref,
              amount: data.payment_data.amount,
              currency: data.payment_data.currency,
              redirect_url: data.payment_data.redirect_url,
              payment_options: data.payment_data.payment_options,
              customer: {
                email: data.payment_data.customer.email,
                phone_number: data.payment_data.customer.phone_number,
                name: data.payment_data.customer.name,
              },
              customizations: {
                title: data.payment_data.customizations.title,
                description: data.payment_data.customizations.description,
                logo: data.payment_data.customizations.logo,
              },
              callback: function (payment) {
                payment.ignore_modal = false;
                verifyTransactionOnBackend(payment);
              },
              onclose: function (incomplete) {
                if (incomplete) {
                  Swal.fire({
                    title: 'Payment Cancelled',
                    text: 'Your payment has been cancelled.',
                    icon: 'warning',
                  });
                }
              },
            };

            // Open Flutterwave popup
            modal = FlutterwaveCheckout(paymentData);
          }
        } else {
          Swal.fire({
            title: 'Error',
            text: data.message,
            icon: 'warning',
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'An error occurred while processing your request.',
          icon: 'error',
        });
      })
      .finally(() => {
        // Re-enable button and hide loading button
        loadingBtn.classList.add('hidden');
        button.classList.remove('hidden');
      });
  });

  // Handle payment callback
  function verifyTransactionOnBackend(data) {
    const transactionStatusContainer = document.getElementById(
      'transactionStatusContainer',
    );
    if (typeof data == 'string') {
      data = JSON.parse(data);
    }
    if (!data.ignore_modal && modal) modal.close();

    if (data.status == 'successful') {
      Swal.fire({
        title: 'Payment Successful',
        text: 'Your payment has been confirmed.',
        icon: 'success',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/dashboard/';
        }
      });
    } else {
      Swal.fire({
        title: 'Payment Failed',
        text: 'Your payment has failed. If you were charged, please contact us immediately for assistance. This may be due to a temporary issue or internet connection problem.',
        icon: 'error',
      });
    }
  }
});
