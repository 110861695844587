import ReconnectingWebSocket from './reconnect_websocket';

document.addEventListener('DOMContentLoaded', function () {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  const domain =
    protocol == 'ws:' ? '127.0.0.1:8000' : window.location.hostname;
  const notificationContainer = document.getElementById('notification-list');

  const notificationWebsocketURL = `${protocol}//${domain}/ws/notification/`;

  const notificationSocket = new ReconnectingWebSocket(
    notificationWebsocketURL,
  );

  notificationSocket.addEventListener('open', (event) => {});

  notificationSocket.addEventListener('message', (event) => {
    if (notificationContainer) {
      document.getElementById('refresh-button')?.click();
    }
    // notify the user of new notification from ****
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      showConfirmButton: false,
      iconColor: 'white',
      customClass: {
        popup: 'colored-toast',
      },
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: 'info',
      title: `You have a new notification`,
    });
  });

  notificationSocket.addEventListener('error', (event) => {
    console.error('WebSocket error:', event);
  });
});
