import { getDocument, GlobalWorkerOptions } from './pdfjs/pdf.mjs';

GlobalWorkerOptions.workerSrc = './pdfjs/pdf.worker.mjs';

const canvas = document.getElementById('pdf_viewer');
const pdfUrl = canvas?.getAttribute('data-pdf-url');
const pageNum = document.getElementById('page_num');
const pageCount = document.getElementById('page_count');
const currentPage = document.getElementById('current_page');
const previousPageBtn = document.getElementById('prev_page_btn');
const nextPageBtn = document.getElementById('next_page_btn');
const zoomInBtn = document.getElementById('zoom_in_btn');
const zoomOutBtn = document.getElementById('zoom_out_btn');

const initialState = {
  pdfDoc: null,
  currentPage: 1,
  pageCount: 0,
  zoom: 1,
};

if (pdfUrl) {
  const loadingTask = getDocument(pdfUrl);
  loadingTask.promise
    .then((data) => {
      initialState.pdfDoc = data;
      pageCount.textContent = initialState.pdfDoc._pdfInfo.numPages;

      renderPage();
    })
    .catch((error) => {
      alert(error.message);
    });
}

const renderPage = () => {
  // Load the first page.
  initialState.pdfDoc.getPage(initialState.currentPage).then((page) => {
    const ctx = canvas.getContext('2d');
    const viewport = page.getViewport({
      scale: initialState.zoom,
    });

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render the PDF page into the canvas context.
    const renderCtx = {
      canvasContext: ctx,
      viewport: viewport,
    };

    page.render(renderCtx);

    pageNum.textContent = initialState.currentPage;
  });
};

const showPrevPage = () => {
  if (initialState.pdfDoc === null || initialState.currentPage <= 1) return;
  initialState.currentPage--;
  // Render the current page.
  currentPage.value = initialState.currentPage;
  renderPage();
};

const showNextPage = () => {
  if (
    initialState.pdfDoc === null ||
    initialState.currentPage >= initialState.pdfDoc._pdfInfo.numPages
  )
    return;

  initialState.currentPage++;
  currentPage.value = initialState.currentPage;
  renderPage();
};

previousPageBtn?.addEventListener('click', showPrevPage);
nextPageBtn?.addEventListener('click', showNextPage);

currentPage?.addEventListener('keypress', (event) => {
  if (initialState.pdfDoc === null) return;
  // Get the key code.
  const keycode = event.keyCode ? event.keyCode : event.which;

  if (keycode === 13) {
    // Get the new page number and render it.
    let desiredPage = currentPage.valueAsNumber;
    initialState.currentPage = Math.min(
      Math.max(desiredPage, 1),
      initialState.pdfDoc._pdfInfo.numPages,
    );

    currentPage.value = initialState.currentPage;
    renderPage();
  }
});

zoomInBtn?.addEventListener('click', () => {
  if (initialState.pdfDoc === null) return;
  initialState.zoom *= 4 / 3;
  renderPage();
});

zoomOutBtn?.addEventListener('click', () => {
  if (initialState.pdfDoc === null) return;
  initialState.zoom *= 2 / 3;
  renderPage();
});
